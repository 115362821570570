import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby-plugin-intl"
import styled from "styled-components"
import get from "lodash/get"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/layout"
import { colors } from "../theme"
import { injectIntl } from "gatsby-plugin-intl"
import moment from "moment"
import "moment/locale/fi"
import ArrowIcon from "../components/arrow-icon"
import { TranslationContext } from "../translate"
import { contentOptions  } from "../rich-text"

const StyledPostContent = styled.div`
  padding: 2em 2em 4em 2em;
  max-width: 55em;
  margin: 0 auto;
  h1,
  h3,
  h1 {
    font-size: 2.5em;
    font-weight: bold;
    margin-top: 0.6em;
    color: #666564;
  }
  h2 {
    font-size: 1.8em;
    color: #969592;
    margin-top: 0.6em;
  }
  h3 {
    font-size: 1.5em;
    font-weight: bold;
    color: ${colors.kaisla};
    margin-bottom: 0;
    margin-top: 2em;
  }
  h4 {
    font-size: 1.25em;
    font-weight: bold;
  }
  h5 {
    font-size: 1.1em;
    font-weight: bold;
  }
  h6 {
    font-size: 1em;
    font-weight: bold;
  }
  a {
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
    color: ${colors.kaisla};
    cursor: pointer;
    &:hover {
      color: ${colors.havu};
    }
  }
  ul,
  ol {
    background: #f5f3ee;
    padding: 1em 2.5em;
  }
  li {
    margin: 0;
    padding: 0.25em 0em;
  }
  li p {
    margin: 0;
  }
  blockquote {
    color: ${colors.kaisla};
    border-left: 0.5em solid ${colors.kaisla};
    margin: 0.5em 0;
    padding: 0.5em 1em;
    font-size: 1.2em;
    font-weight: bold;
    font-style: italic;
  }
  p {
    line-height: 1.5em;
  }
`

const LeadParagraph = styled.div`
  color: ${({ color }) => (color ? colors[color] : "inherit")};
  font-style: italic;
  font-weight: bold;
  font-size: 1.5em;
  margin: 1.3em 0 1.5em 0;
  line-height: 1.5em;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 769px) {
    flex-direction: row;
  }
`

const ContentText = styled.div`
  flex: 1;
  text-align: left;
  white-space: pre-wrap;
`

const EmbeddedContentImage = styled("img")`
  width: 100%;
  display: block;
`

const ContentVideoWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
`

const ContentVideo = styled("video")`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${colors.pakuri};
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 1em;
`

const PostHeader = styled.div`
  border-bottom: 3px solid #969592;
  display: flex;
  justify-content: space-between;
`

const Date = styled.p`
  color: #969592;
`

const BackButton = styled(Link)`
  color: ${({ color }) => color} !important;
  font-size: 1.3em;
  font-weight: 400 !important;
  font-style: normal !important;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none !important;
  &:hover {
    color: ${colors.havu} !important;
  }
`

const FlippedArrow = styled.div`
  transform: rotate(180deg);
  margin-right: 0.5em;
`

const LinkTitle = styled.div`
  padding-top: 0.3em;
`

const Post = ({ data, intl, location }) => {
  const post = data.contentfulPost
  const title = get(post, "title")
  const color = get(post, "color")
  const leadParagraph = get(post, "leadParagraph")
  const content = get(post, "content")
  const image = get(post, "image")
  const createdAt = get(post, "createdAt")
  const isFinnish = intl.locale === "fi"
  const creationDate = moment(createdAt).format("L")
  moment.locale(isFinnish ? "fi" : "en")
  return (
    <Layout data={data} location={location}>
      <TranslationContext.Consumer>{(t) =>
      <>
      <StyledPostContent>
        <PostHeader>
          <BackButton to={"/news"} color={"#969592"}>
            <FlippedArrow>
              <ArrowIcon />
            </FlippedArrow>
            <LinkTitle>{isFinnish ? "Ajankohtaista" : "News"}</LinkTitle>
          </BackButton>
          <Date>{creationDate}</Date>
        </PostHeader>
        <h1>{t(title)}</h1>
        <LeadParagraph color={color}>
          {t(leadParagraph.leadParagraph)}
        </LeadParagraph>
        <Image src={image?.localFile.childImageSharp.fixed.src} />
        <Content>
          <ContentText>
            {content && renderRichText(t(content, true), contentOptions)}
          </ContentText>
        </Content>
      </StyledPostContent>
      </>
      }</TranslationContext.Consumer>
    </Layout>
  )
}

export const query = graphql`
  query ContentfulPost($slug: String, $locale: String) {
    contentfulPost(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      createdAt
      title
      color
      leadParagraph {
        leadParagraph
      }
      image {
        localFile {
          childImageSharp {
            fixed(width: 2400) {
              src
            }
          }
        }
      }
      content {
        raw
      }
      node_locale
      ogTitle
      ogDescription
      ogImage {
        localFile {
          childImageSharp {
            fixed(width: 900) {
              src
            }
          }
        }
      }
    }
    allContentfulLayoutSettings(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          description
          introLine1
          introLine2
          introContent {
            introContent
          }
          primaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          secondaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          tertiaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          headerVideo {
            localFile {
              publicURL
            }
          }
          headerVideoWebm {
            localFile {
              publicURL
            }
          }
          cookieConsentText
          cookieConsentButtonText
          sideMenuButtons {
            ...Button
          }
          primaryMenuButtons {
            ...Button
          }
          secondaryMenuButtons {
            ...Button
          }
          footerMenuButtons {
            ...Button
          }
          ogTitle
          ogDescription
          ogImage {
            localFile {
              childImageSharp {
                fixed(width: 900) {
                  src
                }
              }
            }
          }
          serviceNotification
          showNotificationBanner
          notificationBannerContent {
            raw
          }
        }
      }
    }
    allContentfulContactInformation(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          icon {
            localFile {
              publicURL
            }
          }
          order
          linkUrl
          label
        }
      }
    }
    allContentfulSocialMediaLink(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          url
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default injectIntl(Post)
