import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const Message = styled.div`
  padding: 2em;
  text-align: center;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
`

const NotFoundPage = ({data, location}) => (
  <Layout data={data} location={location}>
    <Message>
      <span>Page Not Found</span>
    </Message>
  </Layout>
)

export const query = graphql `
  query Contentful404Page($locale: String) {
    allContentfulHomeSection(filter: { node_locale: { eq: $locale } } sort: { fields: [order], order: ASC }) {
      edges {
        node {
          identifier
          title
          subtitle
          order
          primaryNavigation
        }
      }
    }

    allContentfulLayoutSettings(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          description
          introLine1
          introLine2
          introContent {
            introContent
          }
          primaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          secondaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          tertiaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          headerVideo {
            localFile {
              publicURL
            }
          }
          headerVideoWebm {
            localFile {
              publicURL
            }
          }
          cookieConsentText
          cookieConsentButtonText
          sideMenuButtons {
            ...Button
          }
          primaryMenuButtons {
            ...Button
          }
          secondaryMenuButtons {
            ...Button
          }
          footerMenuButtons {
            ...Button
          }
          ogTitle
          ogDescription
          ogImage {
            localFile {
              childImageSharp {
                fixed(width: 900) {
                  src
                }
              }
            }
          }
          serviceNotification
          showNotificationBanner
          notificationBannerContent {
            raw
          }
        }
      }
    }
    allContentfulContactInformation(filter: {node_locale: {eq: $locale}} sort: { fields: [order], order: ASC }) {
      edges {
        node {
          icon {
            localFile {
              publicURL
            }
          }
          order
          linkUrl
          label
        }
      }
    }
    allContentfulSocialMediaLink(filter: {node_locale: {eq: $locale}} sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          url
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default NotFoundPage
