import React, { useState, useEffect } from "react"
import { injectIntl, Link, IntlContextConsumer } from "gatsby-plugin-intl"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import get from "lodash/get"
import CookieConsent from "react-cookie-consent"
import { ModalProvider } from "styled-react-modal"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import styled from "styled-components"
import ArrowIcon from "../components/arrow-icon"
import SideMenu from "../components/side-menu"
import { ModalBackground } from "../components/popup"
import Button, { StyledExternalButton } from "./button"
import { initDistributor } from "../distributor"
import { colors } from "../theme"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { TranslationContextProvider, TranslationContext } from '../translate'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import "normalize.css"

const languageNames = {
  "en-US": "EN",
  fi: "FI",
  de: "DE",
  ja: "JP",
}

const LayoutWrapper = styled("div")`
  position: relative;
  width: 100%;
  min-height: 100%;
`

const ContactDetail = styled("a")`
  display: flex;
  flex-direction: row-reverse;
  text-decoration: none;
  color: #f5f3ee;
  padding: 0.75em 0;
  &:not(:last-child) {
    border-bottom: 1px solid #4a4b48;
  }
  @media (min-width: 1050px) {
    border-bottom: none !important;
    flex-direction: row;
    margin-left: 1em;
    color: #73726e;
    padding: 0;
  }
  &:hover {
    color: #fff;
  }
`

const ContactDetailIcon = styled("img")`
  width: 1.2em;
  object-fit: contain;
  height: 1.2em;
  margin-left: 0.5em;
  @media (min-width: 1050px) {
    margin-right: 0.5em;
  }
`

const ContactDetailLabel = styled("span")`
  color: inherit;
  flex: 1;
  text-align: left;
`

const LanguageMenu = styled("div")`
  background: #333230;
  color: #73726e;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 1.5em;
  font-size: 0.8em;
  gap: 0.5em;
  margin-top: ${({ visible }) => (visible ? "0" : "-3em")};
  transition: margin-top 200ms ease-out;
  a,
  a:visited {
    color: #fff;
  }
  img {
    height: 1.5em;
    display: block;
    margin: -0.5em 0;
  }
  & > span {
    margin-right: 1em;
  }
  @media (min-width: 769px) {
    justify-content: flex-end;
  }
`

const LanguageMenuItem = styled.a`
  color: #d2c3a7;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  padding: 0 0.5em;
  &:hover {
    color: #ffffff;
  }
`

const CornerMenuButton = styled("div")`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 6.3em;
  height: 4.4em;
  z-index: 11;
  overflow: hidden;
`

const CornerMenuButtonContent = styled("a")`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 0.55em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #b99874;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  transform-origin: 100% 100%;
  transform: rotate(45deg) translateY(0%);
  text-decoration: none;
  user-select: none;
  transition: transform 100ms ease-out;
  @media (min-width: 1050px) {
    transform: rotate(45deg) translateY(-5%);
    &:hover {
      transform: rotate(45deg) translateY(0%);
    }
  }
`

const LanguageMenuButton = styled(CornerMenuButton)`
  display: none;
  @media (min-width: 1050px) {
    display: block;
  }
`

const MobileMenuSlider = styled("div")`
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: auto;
  background: rgb(51, 50, 48);
  height: 100%;
  transition: transform 400ms ease-out;
  transform: translateY(${({ visible }) => (visible ? "0" : "-100%")});
  overflow-y: auto;
  a {
    color: #fff;
  }
`

const MobileMenu = styled("div")`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 20;
  will-change: transform;
  display: flex;
  flex-direction: column;
  transition: all 300ms ease-out;
  @media (min-width: 1050px) {
    display: none;
  }
`

const MobileLanguageMenu = styled(LanguageMenu)`
  margin-top: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: flex-start;
  padding-left: 1em;
  height: 3em;
`

const MobileMenuPadding = styled("div")`
  padding: 2em;
  display: flex;
  flex-direction: column;
  flex: auto;
  justify-content: center;
`

const MobilePrimaryMenu = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: 1.1em;
  margin-top: 1em;
`

const MobilePrimaryMenuItem = styled(Link)`
  display: flex;
  flex-direction: row;
  padding: 1em 0;
  border-bottom: 1px solid #b99874;
  color: #4a4b48;
  text-transform: uppercase;
  align-items: center;
  letter-spacing: 0.1em;
  text-decoration: none;
  & > *:first-child {
    margin-right: 1em;
    color: #b99874;
  }
`

const MobileSecondaryMenu = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 1em 0 0 0;
  font-size: 1.1em;
`

const MobileSecondaryMenuItem = styled(Link)`
  display: flex;
  flex-direction: row;
  padding: 0.5em 0;
  color: #b99874;
  align-items: center;
  text-decoration: none;
  & > *:first-child {
    margin-right: 1em;
  }
`

const MobileSecondaryMenuItemExt = styled.a`
  display: flex;
  flex-direction: row;
  padding: 0.5em 0;
  color: #b99874;
  align-items: center;
  text-decoration: none;
  & > *:first-child {
    margin-right: 1em;
  }
`

const MobileSocialMediaLinks = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2em 2em 2em;
`

const MobileSocialMediaLink = styled("a")`
  width: 3em;
`

const MobileSocialMediaIcon = styled("img")`
  width: 100%;
`

const MobileLanguageMenuItem = styled(LanguageMenuItem)`
  font-weight: 700;
`

const MobileMenuFooter = styled("div")`
  background: #333230;
  padding: 1.5em 2em;
`

const MobileMenuContactDetail = styled(ContactDetail)`
  flex-direction: row !important;
  font-size: 1.1em;
  border-bottom: none !important;
  padding: 0.5em 0;
  & ${ContactDetailIcon} {
    margin-left: 0;
    margin-right: 0.75em;
  }
`

const MobileMenuButton = styled("a")`
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  position: absolute;
  margin-top: -1px;
  right: -1px;
  width: 6.3em;
  height: 4.4em;
  z-index: 11;
  overflow: hidden;
  display: block;
  @media (min-width: 1050px) {
    display: none;
  }
`

const MobileMenuButtonContent = styled("span")`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: #b99874;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  transform-origin: 100% 100%;
  transform: rotate(45deg) translateY(0%);
  text-decoration: none;
  user-select: none;
  transition: transform 100ms ease-out;
`

const MobileMenuButtonBar = styled("span")`
  display: block;
  width: 1.5em;
  border-top: 0.3em solid #f5f3ee;
  margin-top: 0.3em;
  will-change: transform;
  transition: transform 300ms ease-out;
  &:first-child {
    transform: ${({ alternative }) =>
      alternative ? "translate3d(0,100%,0)" : "none"};
  }
  &:last-child {
    transform: ${({ alternative }) =>
      alternative ? "translate3d(0,-100%,0) rotate(90deg)" : "none"};
  }
`

const Hero = styled("div")`
  position: relative;
  min-height: 5em;
  @media (min-width: 1050px) {
    min-height: 6em;
  }
`

const Header = styled("header")`
  padding: 0 2em;
  height: 65px;
  margin-bottom: -65px;
  box-sizing: border-box;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 1050px) {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(51, 50, 48, 0.85);
  }
`

const HeaderTitle = styled("h1")`
  width: 100%;
  max-width: 3.5em;
  margin: 0 auto;
  @media (min-width: 1050px) {
    margin: 0;
  }
`

const HeaderTitleLink = styled(Link)`
  display: block;
  width: 100%;
`

const HeaderLogo = styled("img")`
  width: 100%;
  display: none;
  @media (min-width: 1050px) {
    display: block;
  }
`

const HeaderLogoSecondary = styled(HeaderLogo)`
  display: block;
  @media (min-width: 1050px) {
    display: none;
  }
`

const PrimaryMenu = styled("nav")`
  z-index: 10;
  display: none;
  font-size: 0.9em;
  @media (min-width: 1050px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  ${StyledExternalButton} {
    margin-left: 1em;
    color: #fff;
    &:hover {
      background: #fff;
      color: ${colors.pakuri};
    }
  }
`

const PrimaryMenuItem = styled(Link)`
  color: #fff;
  padding: 0 1em;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`

const Bullet = styled("span")`
  color: #b99874;
`

const SecondaryMenu = styled("nav")`
  position: absolute;
  top: 3.5em;
  right: 6.5em;
  z-index: 10;
  display: none;
  @media (min-width: 1050px) {
    display: block;
  }
`

const SecondaryMenuItem = styled(Link)`
  color: #d2c3a7;
  padding: 0 0.5em;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`

const SecondaryMenuItemExt = styled.a`
  color: #d2c3a7;
  padding: 0 0.5em;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`

const SocialMediaLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`

const SocialMediaLink = styled("a")`
  display: block;
  width: 2em;
`

const SocialMediaIcon = styled("img")`
  display: block;
  width: 100%;
  &:hover {
    filter: brightness(2) saturate(0.2);
  }
`

const Footer = styled("footer")`
  background: #333230;
  color: #EFEFEE;
  font-size: 0.9em;
  padding: 2em;
  display: flex;
  flex-direction: column-reverse;
  margin: 0;
  position: relative;
  a {
    color: #EFEFEE;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (min-width: 1050px) {
    flex-direction: row;
  }
`

const FooterLogo = styled("img")`
  width: 100%;
  max-width: 12em;
  height: auto;
  margin: 3em auto;
  display: block;
  align-self: center;
  @media (min-width: 1050px) {
    margin: 0 auto;
  }
`

const FooterContent = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-align: flex-end;
  margin: 2em 0;
`

const FooterMenu = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1.2em;
  gap: 2em;
`

const FooterColumns = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 60em;
  margin: 1em auto;
  gap: 2em;
  @media (min-width: 769px) {
    flex-direction: row;
  }
`

const FooterColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const FooterItem = styled.div`
  margin-bottom: 0.75em;
  line-height: 1.5em;
`


const FooterMenuLink = styled(Link)`
  color: #b99874;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`

const FooterMenuBullet = styled("span")`
  color: #73726e;
  margin: 0 0.5em;
`

const FooterContactDetails = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: center;
  margin-top: 1em;
  @media (min-width: 1050px) {
    flex-direction: row;
    align-self: stretch;
    margin-top: 0;
  }
`

const SubFooter = styled("div")`
  background: #4a4b48;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  text-align: left;
  color: #969592;
  a {
    color: #969592;
    font-weight: normal;
    margin: 0 0.5em;
    text-decoration: none;
    &:hover {
      color: #dcdcdb;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const SubFooterBlock = styled.div`

`

const Content = styled.div`
  position: relative;
  overflow: hidden;
`

const BackToTopLink = styled.a`
  position: absolute;
  bottom: 100%;
  margin-bottom: -0.5em;
  left: 50%;
  width: 4em;
  height: 3em;
  margin-left: -2em;
  background: transparent url(${require("../images/back-to-top.svg")}) no-repeat
    center bottom;
  cursor: pointer;
  @media (min-width: 1050px) {
    width: 6em;
    height: 4em;
    margin-left: -3em;
  }
`

const ServiceNotification = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  text-align: center;
  z-index: 1;
  font-weight: bold;
  color: #fff;
  box-shadow: 0 0 0.5em #000;
  background: ${colors.mustikka};
`

const NotificationBanner = styled(ServiceNotification)`
  font-weight: normal;
  padding: 0.25em 1em;
  a {
    color: #fff;
  }
`

const Layout = ({ data, children, heroContent, intl, location }) => {
  const layoutSettings = get(data, "allContentfulLayoutSettings.edges[0].node")
  const pageSettings = get(data, "contentfulPage")
  const postSettings = get(data, "contentfulPost")

  const ogTitle =
    (get(pageSettings, "ogTitle") ?? get(pageSettings, "title")) ||
    (get(postSettings, "ogTitle") ?? get(postSettings, "title")) ||
    get(layoutSettings, "ogTitle")
  const ogDescription =
    (get(pageSettings, "ogDescription") ?? get(pageSettings, "subtitle")) ||
    (get(postSettings, "ogDescription") ??
      get(postSettings, "leadParagraph.leadParagraph")) ||
    get(layoutSettings, "ogDescription")
  const ogImage =
    (get(pageSettings, "ogImage.localFile.childImageSharp.fixed.src") ??
      get(pageSettings, "headerImage.localFile.childImageSharp.fixed.src")) ||
    (get(postSettings, "ogImage.localFile.childImageSharp.fixed.src") ??
      get(postSettings, "image.localFile.childImageSharp.fixed.src")) ||
    get(layoutSettings, "ogImage.localFile.childImageSharp.fixed.src")

  const meta = []
  if (ogTitle) {
    meta.push({ property: `og:title`, content: ogTitle })
    meta.push({ property: `twitter:title`, content: ogTitle })
  }
  if (ogDescription) {
    meta.push({ property: `og:description`, content: ogDescription })
    meta.push({ property: `twitter:description`, content: ogDescription })
  }
  if (ogImage) {
    meta.push({ property: `og:image`, content: ogImage })
  }

  const siteTitle = get(layoutSettings, "title")
  const siteDescription = get(layoutSettings, "description")
  const primaryLogoUrl = get(layoutSettings, "primaryLogo.localFile.childImageSharp.fixed.src")
  const secondaryLogoUrl = get(layoutSettings, "secondaryLogo.localFile.childImageSharp.fixed.src")
  const tertiaryLogoUrl = get(layoutSettings, "tertiaryLogo.localFile.childImageSharp.fixed.src")
  const cookieConsentText = get(layoutSettings, "cookieConsentText")
  const cookieConsentButtonText = get(layoutSettings, "cookieConsentButtonText")
  const serviceNotification = get(layoutSettings, "serviceNotification")
  const showNotificationBanner = get(layoutSettings, "showNotificationBanner")
  const notificationBannerContent = get(layoutSettings, "notificationBannerContent")

  const socialMediaLinks = get(data, "allContentfulSocialMediaLink.edges")
  const contactDetails = get(data, "allContentfulContactInformation.edges")

  const sideMenuButtons = get(layoutSettings, "sideMenuButtons") || []
  const primaryMenuButtons = get(layoutSettings, "primaryMenuButtons") || []
  const secondaryMenuButtons = get(layoutSettings, "secondaryMenuButtons") || []
  const footerMenuButtons = get(layoutSettings, "footerMenuButtons") || []

  const [languageMenuVisible, setLanguageMenuVisible] = useState(false)
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

  const toggleMobileMenu = e => {
    e.preventDefault()
    setMobileMenuVisible(!mobileMenuVisible)
    return false
  }

  const hideMobileMenu = () => {
    setMobileMenuVisible(false)
  }

  useEffect(() => {
    initDistributor(intl.locale)
  })

  const isFinnish = intl.locale === "fi"
  const fullTitle = (ogTitle === siteTitle) ? siteTitle : `${ogTitle} - ${siteTitle}`

  let currentPath = "";
  if(location && location.pathname) {
    currentPath = location.pathname.split("/").slice(2).join("/")
    if(currentPath.length > 0) {
      currentPath = "/" + currentPath
    }
  }

  const lct = useLocation()

  const [cookieBannerVisible, setCookieBannerVisible] = useState(false)

  const breakpoints = useBreakpoint()
  const isDesktop = !breakpoints.sm
  const langMenu = <LanguageMenu visible={true}>
    {isDesktop && <><span style={{whiteSpace: "nowrap"}}>Solvallanrinne 2, 02820 Espoo, Finland</span>
    <span style={{whiteSpace: "nowrap"}}>+358 40 544 4707</span></>}
    <a href="/en-US"><img alt="In English" src={require("../images/uk-flag.png")} /></a>
    <a href="/fi"><img alt="Suomeksi" src={require("../images/fi-flag.png")} /></a>
    <a href="/de"><img alt="Auf Deutsch" src={require("../images/de-flag.png")} /></a>
    <a href="/fr"><img alt="En français" src={require("../images/fr-flag.png")} /></a>
    <a href="/es"><img alt="En español" src={require("../images/es-flag.png")} /></a>
    <a href="/ja"><img alt="日本語で" src={require("../images/jp-flag.png")} /></a>
    <a href="/ko"><img alt="한국어로" src={require("../images/ko-flag.png")} /></a>
  </LanguageMenu>

  return (
    <TranslationContextProvider locale={intl.locale}>
      <TranslationContext.Consumer>{(t) =>
      <ModalProvider backgroundComponent={ModalBackground}>
        <LayoutWrapper>
          <SEO title={fullTitle} description={ogDescription || siteDescription} meta={meta} />
          <Helmet>
            {isFinnish && (
              <script>
                {`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/1447313a88ed4515f462fa984/8736c1734a56b3afe41685af0.js");`}
              </script>
            )}
            <script>
              {`window.embeddedChatbotConfig = {
                  chatbotId: "8zS0p-Y2zmQhQmEJpB8vj",
                  domain: "www.chatbase.co"
              }`}
            </script>
            <script src="https://www.chatbase.co/embed.min.js" chatbotId="8zS0p-Y2zmQhQmEJpB8vj" domain="www.chatbase.co" defer></script>
          </Helmet>


          {langMenu}

          <MobileMenuSlider visible={mobileMenuVisible}>
              <MobileMenuPadding>
                <MobilePrimaryMenu>
            {isFinnish ?
              <>
                <PrimaryMenuItem onClick={hideMobileMenu} to="#hotel-and-glamping">Hotelli</PrimaryMenuItem>
                <PrimaryMenuItem onClick={hideMobileMenu} to="#hotel-and-glamping">Glamping</PrimaryMenuItem>
                <PrimaryMenuItem onClick={hideMobileMenu} to="#bistro">Bistro</PrimaryMenuItem>
                <PrimaryMenuItem onClick={hideMobileMenu} to="#finnish-sauna">Sauna</PrimaryMenuItem>
                <PrimaryMenuItem onClick={hideMobileMenu} to="/meetings">Kokous</PrimaryMenuItem>
                <div style={{height: "1em"}}></div>
                <StyledExternalButton href="#" className="distributor">Varaa majoitus</StyledExternalButton>
                {/*<StyledExternalButton href="https://fareharbor.com/embeds/book/haltialakelodge/?full-items=yes&flow=533137&language=fi">Varaa elämyksiä</StyledExternalButton>*/}
                <StyledExternalButton href="https://haltia-lake-lodge.vouchercart.com/app/" target="_blank" rel="noopener noreferrer">Osta lahjaksi</StyledExternalButton>
              </>
              :
              <>
                <PrimaryMenuItem onClick={hideMobileMenu} to="#hotel-and-glamping">{t("Hotel")}</PrimaryMenuItem>
                <PrimaryMenuItem onClick={hideMobileMenu} to="#hotel-and-glamping">{t("Glamping")}</PrimaryMenuItem>
                <PrimaryMenuItem onClick={hideMobileMenu} to="#bistro">{t("Bistro")}</PrimaryMenuItem>
                <PrimaryMenuItem onClick={hideMobileMenu} to="#finnish-sauna">{t("Sauna")}</PrimaryMenuItem>
                <PrimaryMenuItem onClick={hideMobileMenu} to="/meetings">{t("Meetings")}</PrimaryMenuItem>
                <div style={{height: "1em"}}></div>
                <StyledExternalButton href="#" className="distributor">{t("Book a stay")}</StyledExternalButton>
                {/*<StyledExternalButton href="https://fareharbor.com/embeds/book/haltialakelodge/?full-items=yes&flow=533137&language=en">{t("Book experiences")}</StyledExternalButton>*/}
                <StyledExternalButton href="https://haltia-lake-lodge.vouchercart.com/app/" target="_blank" rel="noopener noreferrer">{t("Buy as gift")}</StyledExternalButton>
              </>
            }
                </MobilePrimaryMenu>
              </MobileMenuPadding>
              <MobileSocialMediaLinks>
                {socialMediaLinks.map(({ node }, i) => (
                  <MobileSocialMediaLink
                    key={i}
                    target="_blank"
                    rel="noreferrer"
                    href={node.url}
                    index={i}
                  >
                    <MobileSocialMediaIcon
                      alt={node.title}
                      src={node.icon.localFile.publicURL}
                    />
                  </MobileSocialMediaLink>
                ))}
              </MobileSocialMediaLinks>
              <MobileMenuFooter>
                {contactDetails.map(({ node }, i) => (
                  <MobileMenuContactDetail
                    key={i}
                    target="_blank"
                    rel="noreferrer"
                    href={node.linkUrl}
                  >
                    <ContactDetailIcon src={node.icon.localFile.publicURL} />
                    <ContactDetailLabel>{node.label}</ContactDetailLabel>
                  </MobileMenuContactDetail>
                ))}
              </MobileMenuFooter>
            </MobileMenuSlider>
            <MobileMenu visible={mobileMenuVisible}>
              <MobileMenuButton onClick={toggleMobileMenu}>
                <MobileMenuButtonContent>
                  <MobileMenuButtonBar alternative={mobileMenuVisible} />
                  <MobileMenuButtonBar alternative={mobileMenuVisible} />
                </MobileMenuButtonContent>
              </MobileMenuButton>
            </MobileMenu>


          <Header>
            <HeaderTitle>
              <HeaderTitleLink to="/">
                <HeaderLogo src={primaryLogoUrl} alt={siteTitle} />
                <HeaderLogoSecondary src={secondaryLogoUrl} alt={siteTitle} />
              </HeaderTitleLink>
            </HeaderTitle>
            {isFinnish ?
              <PrimaryMenu>
                <PrimaryMenuItem to="#hotel-and-glamping">Hotelli</PrimaryMenuItem>
                <PrimaryMenuItem to="#hotel-and-glamping">Glamping</PrimaryMenuItem>
                <PrimaryMenuItem to="#bistro">Bistro</PrimaryMenuItem>
                <PrimaryMenuItem to="#finnish-sauna">Sauna</PrimaryMenuItem>
                <PrimaryMenuItem to="/meetings">Kokous</PrimaryMenuItem>
                <StyledExternalButton href="#" className="distributor">Varaa majoitus</StyledExternalButton>
                {/*<StyledExternalButton href="https://fareharbor.com/embeds/book/haltialakelodge/?full-items=yes&flow=533137&language=fi">Varaa elämyksiä</StyledExternalButton>*/}
                <StyledExternalButton href="https://haltia-lake-lodge.vouchercart.com/app/" target="_blank" rel="noopener noreferrer">Osta lahjaksi</StyledExternalButton>
              </PrimaryMenu>
              :
              <PrimaryMenu>
                <PrimaryMenuItem to="#hotel-and-glamping">{t("Hotel")}</PrimaryMenuItem>
                <PrimaryMenuItem to="#hotel-and-glamping">{t("Glamping")}</PrimaryMenuItem>
                <PrimaryMenuItem to="#bistro">{t("Bistro")}</PrimaryMenuItem>
                <PrimaryMenuItem to="#finnish-sauna">{t("Sauna")}</PrimaryMenuItem>
                <PrimaryMenuItem to="/meetings">{t("Meetings")}</PrimaryMenuItem>
                <StyledExternalButton href="#" className="distributor">{t("Book a stay")}</StyledExternalButton>
                {/*<StyledExternalButton href="https://fareharbor.com/embeds/book/haltialakelodge/?full-items=yes&flow=533137&language=en">{t("Book experiences")}</StyledExternalButton>*/}
                <StyledExternalButton href="https://haltia-lake-lodge.vouchercart.com/app/" target="_blank" rel="noopener noreferrer">{t("Buy as gift")}</StyledExternalButton>
              </PrimaryMenu>
            }
          </Header>
          <Hero>
            {heroContent}
          </Hero>

          <Content>{children}</Content>

          <Footer>
            <FooterContent>
              <FooterMenu>
                {footerMenuButtons.map((button, i) => (
                  <React.Fragment key={i}>
                    <Button {...button} wrapperComponent={FooterMenuLink} />
                  </React.Fragment>
                ))}
              </FooterMenu>
              <FooterColumns>
                <FooterColumn>
                  <FooterItem>
                    <a href="mailto:info@haltialakelodge.com">info@haltialakelodge.com</a>
                  </FooterItem>

                  <FooterItem>
                    +358 40 544 4707
                  </FooterItem>

                  <FooterItem>
                    Solvallanrinne 2<br/>
                    02820 Espoo<br/>
                    Finland
                  </FooterItem>

                </FooterColumn>

                {isFinnish ? <>
                  <FooterColumn>
                    <FooterItem><strong>Haltia Lake Lodge</strong></FooterItem>
                    <FooterItem><a href="#hotelli">Hotelli</a></FooterItem>
                    <FooterItem><a href="#glamping">Glamping</a></FooterItem>
                    <FooterItem><a href="#bistro">Bistro</a></FooterItem>
                    <FooterItem><a href="#sauna">Sauna</a></FooterItem>
                    <FooterItem><Link to="/news">Ajankohtaista</Link></FooterItem>
                    <FooterItem><Link to="/contact-us">Yhteystiedot</Link></FooterItem>
                    <FooterItem><Link to="/frequently-asked-questions">Usein kysytyt kysymykset</Link></FooterItem>
                  </FooterColumn>
                  <FooterColumn>
                    <FooterItem><strong>Nuuksion kansallispuisto</strong></FooterItem>
                    <FooterItem><a href="https://www.luontoon.fi/nuuksio">Nuuksion verkkosivu</a></FooterItem>
                    <FooterItem><a href="https://www.nuuksiolakeland.fi/">Nuuksio Lakeland - kansallispuiston parhaat palvelut</a></FooterItem>
                  </FooterColumn>
                  <FooterColumn>
                    <FooterItem><strong>Suomen luontokeskus Haltia</strong></FooterItem>
                    <FooterItem><a href="https://haltia.com/">Haltia</a></FooterItem>
                    <FooterItem><a href="https://haltia.com/nayttelyt/">Museo & näyttelyt</a></FooterItem>
                    <FooterItem><a href="https://haltia.com/kokoukset-ja-kongressit/haltian-kokoustilat/">Kokous- ja oheispalvelut</a></FooterItem>
                    <FooterItem><a href="https://haltia.com/retkelle-nuuksioon/">Haltian lähiluonto</a></FooterItem>
                    <FooterItem><a href="https://ravintolahaltia.fi/">Ravintola Haltia</a></FooterItem>
                  </FooterColumn>
                </>

              :

              <>
                  <FooterColumn>
                    <FooterItem><strong>{t("Haltia Lake Lodge")}</strong></FooterItem>
                    <FooterItem><a href="#hotelli">{t("Hotel")}</a></FooterItem>
                    <FooterItem><a href="#glamping">{t("Glamping")}</a></FooterItem>
                    <FooterItem><a href="#bistro">{t("Bistro")}</a></FooterItem>
                    <FooterItem><a href="#sauna">{t("Sauna")}</a></FooterItem>
                    <FooterItem><Link to="/contact-us">{t("Contact Us")}</Link></FooterItem>
                    <FooterItem><Link to="/frequently-asked-questions">{t("Frequently Asked Questions")}</Link></FooterItem>
                  </FooterColumn>
                  <FooterColumn>
                    <FooterItem><strong>Nuuksio National Park</strong></FooterItem>
                    <FooterItem><a href="https://www.nationalparks.fi/nuuksionp">{t("Nuuksio website")}</a></FooterItem>
                    <FooterItem><a href="https://www.nuuksiolakeland.fi/">{t("Nuuksio Lakeland - the best services in the national park")}</a></FooterItem>
                  </FooterColumn>
                  <FooterColumn>
                    <FooterItem><strong>{t("Finnish Nature Centre Haltia")}</strong></FooterItem>
                    <FooterItem><a href="https://haltia.com/en/">{t("Haltia")}</a></FooterItem>
                    <FooterItem><a href="https://haltia.com/en/exhibitions/">{t("Museum & exhibitions")}</a></FooterItem>
                    <FooterItem><a href="https://haltia.com/en/groups-meetings/">{t("Groups & meetings")}</a></FooterItem>
                    <FooterItem><a href="https://haltia.com/en/hiking-in-nuuksio/">{t("Hiking in Nuuksio")}</a></FooterItem>
                    <FooterItem><a href="https://ravintolahaltia.fi/en/frontpage/">{t("Restaurant Haltia")}</a></FooterItem>
                  </FooterColumn>
                </>
              
              }

            
              
              </FooterColumns>
              <FooterColumns>
                <FooterColumn>
                  <SocialMediaLinks>
                      {socialMediaLinks.map(({ node }, i) => (
                        <SocialMediaLink
                          key={i}
                          target="_blank"
                          rel="noreferrer"
                          href={node.url}
                          index={i}
                        >
                          <SocialMediaIcon
                            alt={node.title}
                            src={node.icon.localFile.publicURL}
                          />
                        </SocialMediaLink>
                      ))}
                    </SocialMediaLinks>
                </FooterColumn>
                
              </FooterColumns>
            </FooterContent>
            <BackToTopLink href="#" />
          </Footer>
          {/*<SideMenu buttons={sideMenuButtons} />*/}


          {/*<CookieConsent
            location="bottom"
            buttonText={cookieConsentButtonText}
            style={{
              background: "#4a4b48",
              fontSize: "1em",
              boxSizing: "border-box",
              padding: "1em",
              transform: "translate3d(0,100%,0)",
              animation: "cookie-consent-appear 500ms ease-out 500ms forwards" }}
            overlay={true}
            buttonStyle={{
              color: "#b99874",
              fontSize: "0.9em",
              letterSpacing: "0.1em",
              textTransform: "uppercase",
              background: "transparent",
              border: "1px solid #b99874",
            }}
            declineButtonStyle={{
              color: "#b99874",
              fontSize: "0.9em",
              letterSpacing: "0.1em",
              textTransform: "uppercase",
              background: "transparent",
              border: "1px solid #b99874",
            }}
            cookieName="tracking-cookies-allowed"
            expires={150}
            enableDeclineButton={true}
            onAccept={() => {
              setCookieBannerVisible(false)
              initializeAndTrack(lct)
            }}
            onDecline={() => {
              setCookieBannerVisible(false)
            }}
            declineButtonText={isFinnish ? "Hylkää" : "Decline"}
            visible={cookieBannerVisible ? "show" : "byCookieValue"}
          >
            {cookieConsentText}
            {isFinnish ?
              <a href="/fi/privacy-policy#privacy-policy" style={{color: colors.kaisla, marginLeft: "0.5em"}}>Lue lisää</a> :
              <a href="/en-US/privacy-policy#privacy-policy" style={{color: colors.kaisla, marginLeft: "0.5em"}}>Read more</a>
            }
          </CookieConsent>*/}

          {false && serviceNotification && (serviceNotification !== "") && <ServiceNotification>
            {serviceNotification}
          </ServiceNotification>}

          {showNotificationBanner && notificationBannerContent && <NotificationBanner>
            {renderRichText(t(notificationBannerContent, true))}
          </NotificationBanner>}

        </LayoutWrapper>
      </ModalProvider>
      }
      </TranslationContext.Consumer>
    </TranslationContextProvider>
  )
}

export default injectIntl(Layout)
